import React from 'react'
import Company from '../Company/Company'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'

const Strategy_ = () => {
  return (
    <>
     <Navbar/>     
     <Company/>
     <Footer/>
   </>
  )
}

export default Strategy_