
const IconsData = [
   
            {
                id:1,
                
                logo: 'fas fa-meteor',
                text : 'Advisory',
                Para : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, incidunt.'
            },
            
            {
                id:2,
                logo: 'fas fa-atom',
                text : 'Development',
                Para : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, incidunt.'
            },
            {
                id:3,
                logo: 'fas fa-moon',
                text : 'Production',
                Para : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, incidunt.'
            },
            {
                id:4,
                logo: 'fas fa-meteor',
                text : 'Design',
                Para : 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde, incidunt.'
            },



            
]


export default IconsData;
