import React from 'react'
import Footer from '../Footer/Footer'
import Navbar from '../Navbar/Navbar'
import ProjectsIndex from '../Projects/ProjectsIndex'

const Products_ = () => {
  return (
    <>
     <Navbar/>
     <ProjectsIndex/>
     <Footer/>
   </>
  )
}

export default Products_